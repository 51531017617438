<script lang="ts" setup>
	import type { IDealership } from "~/server/models/dealership";
	import { ClientReloadPolicy, type StoreRecordData } from "~~/services/store";

	const props = defineProps<{
		id: string;
		reloadOnClient?: ClientReloadPolicy;
	}>();

	const id = toRef(props, "id");

	const { record, operation, task } = useFindRecord<StoreRecordData<IDealership>>(ref("dealerships"), id, {
		reloadOnClient: ClientReloadPolicy.never,
	});

	if (task.value) {
		onServerPrefetch(async () => {
			return task.value;
		});
	}
</script>

<template>
	<slot :record="record" :operation="operation"></slot>
</template>
