<script lang="ts" setup>
	import { kebabCase } from "lodash-es";
	import type { IPage, IPageField } from "~/server/models/page";
	import type { IPageLead, IPageLeadField } from "~/server/models/page-lead";
	import type { IPageView } from "~/server/models/page-view";
	import { ClientReloadPolicy, StoreProviderTypes, StoreQueryFilterOperators } from "~/services/store";
	import type { CollectionNames, StoreQueryFilter, StoreQueryParams } from "~/services/store";
	import type { DealershipsLocateResponse } from "~/server/api/dealerships/locate.get";
	import type { LeadBody } from "~/server/api/page-leads/index.post";
	import { useUser } from "./../stores/user";

	const id = computed(() => route.params.id as string);
	const route = useRoute();

	const { touch } = useUser();

	definePageMeta({
		layout: "page",
	});

	defineOptions({
		suspensible: false,
	});

	const params = {
		reloadOnClient: ClientReloadPolicy.once,
		provider: StoreProviderTypes.restapi,
	} as StoreQueryParams;

	const { loading, records, operation, task } = useQueryRecords<IPage>(
		ref<CollectionNames>("pages"),
		ref<StoreQueryFilter[]>([
			{
				field: "url",
				operator: StoreQueryFilterOperators.equal,
				value: id.value,
			},
		]),
		params
	);

	const { records: dealerships, task: taskDealerships } = useQueryRecords<IPage>(
		ref<CollectionNames>("dealerships"),
		ref<StoreQueryFilter[]>([
			{
				field: "disabled",
				operator: StoreQueryFilterOperators.equal,
				value: false,
			},
		]),
		params
	);

	const { records: models, task: taskModels } = useQueryRecords<IPage>(
		ref<CollectionNames>("models"),
		ref<StoreQueryFilter[]>([
			{
				field: "disabled",
				operator: StoreQueryFilterOperators.equal,
				value: false,
			},
		]),
		params
	);

	const dealershipsOptions = computed(() => {
		return dealerships.value.map((item) => {
			return {
				label: item.name,
				value: item.id,
			};
		});
	});

	const record = computed(() => {
		return records.value.find((record) => record.url === id.value);
	});

	const modelsOptions = computed(() => {
		return models.value.map((item) => {
			return {
				label: item.name,
				value: item.id,
			};
		});
	});

	function filterModels(models: SelectOption[], options: string[]) {
		return models.filter((item) => {
			return options.includes(item.value);
		});
	}

	function getKey(item: IPageField) {
		return kebabCase(item.label);
	}

	async function save() {
		const fields = record.value?.fields!.map((item) => {
			return {
				label: item.label,
				type: item.type,
				required: item.required,
				value: state.value[getKey(item)],
			} as IPageLeadField;
		});

		const data = {
			page: record.value?.id!,
			fields: fields!,
			user_agent: navigator.userAgent,
		} satisfies LeadBody;

		const newRecord = createRecord<IPageLead>("page-leads", data);

		const { task, operation } = saveRecord(newRecord);

		await task.value;

		if (operation.value?.error) {
			screen.value = "error";
			return;
		}

		screen.value = "complete";
	}

	const dealershipField = computed(() => {
		return record.value?.fields.find((item) => item.type === "dealerships");
	});

	const dealershipId = computed(() => {
		if (dealershipField.value) {
			return state.value[getKey(dealershipField.value)];
		} else {
			return null;
		}
	});

	const modelsField = computed(() => {
		return record.value?.fields.find((item) => item.type === "models");
	});

	const modelId = computed(() => {
		return state.value[getKey(modelsField.value!)];
	});

	if (process.server) {
		// wait for all data
		await Promise.all([task.value, taskDealerships.value, taskModels.value]);
	}

	const state = useState<any>("state", () => {
		return {};
	});

	try {
		if (dealershipField.value) {
			await $fetch<DealershipsLocateResponse>("/api/dealerships/locate", {
				headers: process.server ? useRequestHeaders() : undefined,
			}).then((response) => {
				console.log(response);
				state.value[getKey(dealershipField.value!)] = response.id;
			});
		}
	} catch (error) {
		console.error(error);
	}

	const screen = ref<"input" | "error" | "complete">("input");

	const autosize = ref<HTMLElement | null>(null);
	const panel = ref<HTMLElement | null>(null);

	function updateHeight() {
		if (autosize.value && panel.value) {
			if (autosize.value.scrollHeight > 0) {
				panel.value.style.height = autosize.value.scrollHeight + "px";
			}
		}
	}

	function autoHeight() {
		if (autosize.value && panel.value) {
			panel.value.style.height = "auto";
		}
	}

	//

	onMounted(() => {
		const data = {
			page: record.value?.id!,
			user_agent: navigator.userAgent,
		};

		const newRecord = createRecord<IPageView>("page-views", data);

		const { task } = saveRecord(newRecord);

		task.value?.then(() => {
			//console.log("PageView");
		});
	});
</script>

<template>
	<Head>
		<Title>{{ record?.name ? record.name : "Page not found" }} - Aston Martin</Title>
	</Head>

	<main class="page" :class="[record?.alignment]" v-if="!loading && record && !record.disabled">
		<div
			class="media"
			:class="[{ black: record?.background.status ? record?.background.color === 'black' : false }, { green: record?.background.status ? record?.background.color === 'green' : false }]"
		>
			<div class="mobile-logo-holder"><logo /></div>

			<HLS-video
				:style="{ opacity: record?.background.status && touch === false ? 1 - record?.background.opacity : 1 }"
				v-if="record?.video?.id"
				:src="record?.video?.hls"
				:poster="record?.video?.thumbnail"
				autoplay
				muted
				loop
			></HLS-video>
			<cloudflare-image :style="{ opacity: record?.background.status ? 1 - record?.background.opacity : 1 }" v-if="record?.image" :value="record?.image" :width="1600" />
		</div>

		<div ref="panel" class="panel shadow-large flex-vertical">
			<div ref="autosize" class="autosize">
				<Transition
					name="slide-fade"
					mode="out-in"
					:on-appear="updateHeight"
					:on-after-appear="updateHeight"
					:on-after-leave="updateHeight"
					:on-after-enter="
						() => {
							updateHeight();
							autoHeight();
						}
					"
					:on-before-appear="updateHeight"
					:on-before-enter="updateHeight"
					:on-before-leave="updateHeight"
					:on-enter="updateHeight"
					:on-leave="updateHeight"
				>
					<div class="state input flex-vertical" v-if="screen === 'input'">
						<logo />

						<h1>{{ record?.title }}</h1>
						<p v-if="record?.has_description && record?.description?.length" class="medium color-grey">{{ record?.description }}</p>

						<InputForm :v-if="record ? true : false" :action="save" v-slot="{ form, processing, isValid, submit }" id="pages-basic-information">
							<template v-for="(item, index) in record?.fields">
								<InputTextField
									:class="[item.size]"
									:form="form"
									:placeholder="item.label"
									autocomplete="on"
									v-if="item.type === 'email'"
									:type="item.type"
									:required="item.required"
									v-model:value="state[getKey(item)]"
								/>

								<InputTextField
									:class="[item.size]"
									:form="form"
									:placeholder="item.label"
									autocomplete="on"
									v-if="item.type === 'zip'"
									:type="item.type"
									:required="item.required"
									v-model:value="state[getKey(item)]"
								/>
								<InputTextField
									:class="[item.size]"
									:form="form"
									:placeholder="item.label"
									autocomplete="on"
									v-if="item.type === 'phone'"
									type="us-phone"
									:required="item.required"
									v-model:value="state[getKey(item)]"
								/>

								<InputTextField
									:class="[item.size]"
									:form="form"
									:placeholder="item.label"
									v-if="item.type === 'text'"
									type="text"
									:required="item.required"
									v-model:value="state[getKey(item)]"
								/>

								<InputSelectField
									v-if="item.type === 'dealerships'"
									:placeholder="item.label"
									:required="item.required"
									:options="dealershipsOptions"
									v-model:value="state[getKey(item)]"
								/>

								<InputSelectField
									v-if="item.type === 'models'"
									:placeholder="item.label"
									:required="item.required"
									:options="filterModels(modelsOptions, item.options)"
									v-model:value="state[getKey(item)]"
								/>
							</template>

							<div class="flex-vertical action">
								<Button size="large" state="primary" :loading="processing" @click="submit">{{ record?.button }}</Button>
								<p class="tiny">
									By clicking the "{{ record?.button }}" button above, I consent to the
									<br />
									<a href="https://www.astonmartin.com/legal/terms-and-conditions" target="_blank">Terms and Conditions</a>
									<span>,&#32;</span>
									<a href="https://www.astonmartin.com/legal/privacy" target="_blank">Privacy</a>
									and
									<a href="https://www.astonmartin.com/en-us/legal/cookies" target="_blank">Cookies.</a>
								</p>
							</div>
						</InputForm>
					</div>
					<div class="state flex-vertical complete" v-else-if="screen === 'complete'">
						<logo />

						<h1>{{ record?.complete.title }}</h1>
						<p class="medium color-grey">{{ record?.complete.description }}</p>

						<template v-if="dealershipId ? true : false">
							<record-dealership v-if="record?.call_to_action.dealership" :id="dealershipId" v-slot="{ record: dealership }">
								<div class="dealership flex-vertical">
									<h4>{{ dealership?.name }}</h4>
									<a class="tel" :href="`tel:${dealership?.phone}`">
										<p class="medium color-black">{{ dealership?.phone }}</p>
									</a>

									<a class="email" :href="`mailto:${dealership?.email}`">
										<p class="medium">{{ dealership?.email }}</p>
									</a>

									<p class="small address">
										<span>
											<Address
												v-if="dealership"
												type="multiline"
												:country="dealership?.country"
												:city="dealership?.city"
												:zip="dealership?.zip"
												:state="dealership?.state"
												:street="dealership?.street"
											/>
										</span>
									</p>

									<a class="directions" v-if="dealership?.directions_link" :href="dealership?.directions_link" target="_blank">
										<Button size="medium" state="transparent">Get directions</Button>
									</a>
								</div>
							</record-dealership>
						</template>

						<div class="flex-vertical action">
							<record-model v-if="record?.call_to_action.configurator && modelId" :id="modelId" v-slot="{ record: model }">
								<a v-if="model?.has_configurator && model?.configurator_link" :href="model?.configurator_link" target="_blank">
									<Button size="large" state="primary">Model configurator</Button>
								</a>
							</record-model>

							<a v-if="record?.call_to_action.custom" :href="record?.call_to_action.link">
								<Button size="large" :state="modelId && record?.call_to_action.configurator ? 'secondary' : 'primary'">{{ record?.call_to_action.label }}</Button>
							</a>
						</div>
					</div>
				</Transition>
			</div>
		</div>
	</main>

	<main class="page" v-else>
		<h1>Page not found</h1>
	</main>

	<main class="page" v-if="operation?.error">
		<h1>Error</h1>
	</main>
</template>

<style scoped lang="scss">
	.page {
		display: flex;
		width: 100%;
		min-height: 100%;
		align-items: center;
		padding: 40px;
		justify-content: center;

		@include mobile {
			justify-content: flex-start;
			flex-direction: column;
		}

		@include mobile {
			padding: 0px;
		}

		&.center {
		}

		&.left {
			justify-content: flex-start;
		}

		&.right {
			justify-content: flex-end;
		}

		.media {
			z-index: 1;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: black;
			&.black {
			}

			&.green {
				background: var(--green-dark);
			}

			@include mobile {
				position: relative;
				height: 80vw;
			}

			.mobile-logo-holder {
				display: none;
				@include mobile {
					z-index: 10;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					padding-top: 30px;
					padding-bottom: 60px;
					background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
					:deep(.logo) {
						height: 40px;
						width: auto;
						path {
							fill: white;
						}
					}
				}
			}

			.cloudflare-image,
			.hls-video {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
			}

			.cloudflare-image {
				/* @include mobile {
					opacity: 1 !important;
				} */
			}
			.hls-video {
				/* @include mobile {
					display: none;
				} */
			}
		}

		.state {
			padding: 40px;
			align-items: center;
			width: 440px;
			width: 100%;
			@include mobile {
				padding-left: 30px;
				padding-right: 30px;
				padding-top: 30px;
				padding-bottom: 30px;
				border-radius: 0px;
			}
		}

		.state.complete {
			.dealership {
				width: 100%;
				border-top: 1px solid var(--beige-dark);
				border-bottom: 1px solid var(--beige-dark);
				padding-left: 30px;
				padding-right: 30px;
				padding-top: 30px;
				padding-bottom: 30px;
				text-align: center;
				align-items: center;
				margin-top: 30px;

				h4 + a {
					margin-top: 10px;
				}

				a.tel,
				a.email {
					margin-bottom: 10px;
				}

				a.directions {
				}
			}
		}
		.panel {
			z-index: 10;
			width: 440px;
			border-radius: 18px;
			border: none;
			overflow: hidden;
			height: auto;
			max-height: fit-content;
			transition: all 0.1s linear;

			h1 {
				font-size: 30px;
				line-height: 34px;
			}
			h1,
			p {
				text-align: center;
			}

			h1 + p {
				margin-top: 5px;
			}

			@include mobile {
				width: 100%;
				border-radius: 0px;
				box-shadow: none;
			}

			.slide-fade-enter-active {
				transition: all 0.4s $easeInOutExpo;
			}

			.slide-fade-leave-active {
				transition: all 0.4s $easeInOutExpo;
			}

			.slide-fade-enter-from {
				transform: translateX(20px);
				opacity: 0;
			}
			.slide-fade-leave-to {
				transform: translateX(-20px);
				opacity: 0;
			}

			.autosize {
				display: flex;
				overflow: hidden;
				transition: all 0.4s $easeInOutExpo;
			}

			:deep(.logo) {
				height: 40px;
				width: auto;
				margin-bottom: 15px;
				@include mobile {
					display: none;
				}
			}

			.input-form {
				margin-top: 25px;
				flex-wrap: wrap;
				flex-direction: unset;
				gap: 10px;
				align-items: baseline;

				.full {
				}

				.half {
					width: calc(50% - 5px);
				}

				.action {
					margin-top: 20px;
				}
			}
			.action {
				margin-top: 30px;
				width: 100%;
				gap: 10px;
				text-align: center;
				button {
					width: 100%;
				}
				p {
					a {
						display: inline;
						text-decoration: underline;
					}
				}
			}
		}
	}
</style>
